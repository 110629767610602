.widget-graph-container
{
    width: 50%;
    background-color: white;
    -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
    border-radius: 15px;
    margin-left: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}