.admin-overview-container
{
    display: flex;
    width: 100%;
}
.admin-overview-container-section
{
    margin-right: 20px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    width: 25%;
    border-radius: 15px;
}
.admin-overview-container-section-left
{
    width: 80%;
}
.admin-overview-container-section-left-top
{
    color: #A0AEC0;
    font-weight: bold;
}
.admin-overview-container-section-left-bot 
{
    display: flex;
}
.admin-overview-container-section-left-bot p 
{
    color: #2D3748;
    font-size: 1.1rem;
    font-weight: bold;
}
.admin-overview-container-section-left-bot span 
{
    display: block;
    color: #48BB78;
    margin-left: 10px;
    font-size: 1rem;
}
.admin-overview-container-section-right
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-overview-container-section-right-core
{
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #219EBC;
}
.admin-overview-container-section-right-core img 
{
    height: 25px;
    object-fit: contain;

}