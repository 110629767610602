.terms-container
{
    min-height: 100vh;
    background-image: url("../../../../public/platform/temsBg.jpg");
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
}

.terms-container-core
{
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  min-height: 70vh;
}