.hoteldetails-container {
  min-height: 50vh;
  padding: 30px;
  background-color: white;
  margin: 20px;
  border-radius: 10px;
}
.hoteldetails-container-section-top {
  display: flex;
  min-height: 400px;
}
.hoteldetails-container-section-top-left {
  height: 100%;
  width: 50%;
  min-height: 400px;
}
.hoteldetails-container-section-top-left-header {
  display: flex;
  align-items: center;
}
.hoteldetails-container-section-top-left-header img {
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}
.hoteldetails-container-section-top-left-header p {
  font-size: 1.6rem;
  font-weight: bold;
}
.hoteldetails-container-section-top-left-desc {
  margin-top: 30px;
}
.hoteldetails-container-section-top-left-desc-core {
  padding: 10px 20px;
  font-size: 1.4rem;
  margin-top: 20px;
}
.hoteldetails-container-section-top-left-fac {
  margin-top: 20px;
}
.hoteldetails-container-section-top-left-fac-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 0px 20px;
}
.hoteldetails-container-section-top-left-fac-items-el {
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-top: 10px;
}
.hoteldetails-container-section-top-left-fac-items-el img {
  margin-right: 20px;
}
.hoteldetails-container-section-top-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hoteldetails-container-section-top-right iframe {
  border: 0;
  padding: 0;
  width: 100%;
  height: 90%;
  margin: 30px;
  border-radius: 10px;
}
.hoteldetails-container-section-gallery {
  min-height: 300px;
}
.hoteldetails-container-section-gallery-core {
  height: 100%;
  margin-top: 20px;
}
.hoteldetails-container-section-contact {
  min-height: 150px;
}
.hoteldetails-container-section-contact-core {
  margin-top: 20px;
  display: flex;
}
.hoteldetails-container-section-contact-core-column {
  height: 100%;
  width: 33.33%;
}
.hoteldetails-container-section-contact-core-column-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.hoteldetails-container-section-contact-core-column-row a {
  text-decoration: none;
  display: flex;
  color: black;
  transition: all 0.3s ease;
}
.hoteldetails-container-section-contact-core-column-row a:hover {
  transform: scale(1.1);
}
.hoteldetails-container-section-contact-core-column-row-img {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hoteldetails-container-section-contact-core-column-row-img img {
  margin-right: 20px;
  height: 25px;
  width: 25px;
  object-fit: contain;
}
.hoteldetails-container-section-contact-core-column-row p {
  margin-left: 10px;
}
.hoteldetails-container-section-contact-core-column-row-company {
  color: gray;
  font-style: italic;
  margin-top: 5px;
}
