.date-range-selector-admin
{
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.date-range-selector-admin-section
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
}
.date-range-selector-admin-section p 
{
    margin-right: 10px;
}
.date-range-selector-admin-btn
{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.date-range-selector-admin-btn button 
{
    border: none;
    outline: none;
    padding: 8px 13px;
    background-color: #219EBC;
    border-radius: 5px;
    color: white;
    transition: all 0.3s ease;
}
.date-range-selector-admin-btn button:hover 
{
    transform: scale(1.1);
    cursor: pointer;
}