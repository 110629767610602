.about-magnus-container
{
    width: 50%;
    -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
    border-radius: 15px;
    padding: 20px;
    background-color: white;
    display: flex;
    height: 450px;
}
.about-magnus-container-left
{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.about-magnus-container-left-top
{
}
.about-magnus-container-left-header
{
    color: #A0AEC0;
    font-size: 0.8rem;
}
.about-magnus-container-left-title
{
    color: #2D3748;
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 10px;
}
.about-magnus-container-left-content
{
    color: #A0AEC0;
    margin-top: 15px;
    font-size: 0.9rem;
    width: 90%;
}
.about-magnus-container-left-btns
{
}
.about-magnus-container-left-btns a
{
    text-decoration: none;
    color: #2D3748;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}
.about-magnus-container-left-btns a:hover 
{
    transform: scale(1.05);
}
.about-magnus-container-left-btns a p 
{
    font-size: 1.3rem;
    font-weight: bold;
}
.about-magnus-container-left-btns a img 
{
    height: 20px;
    object-fit: contain;
    margin-left: 15px;
}

.about-magnus-container-right
{
    width: 50%;
    background-color: #219EBC;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-magnus-container-right img 
{
    height: 50px;
    object-fit: contain;
}
