.login-container
{
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container-wrapper
{
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
.login-container-wrapper-core
{
  width: 70%;
  display: flex;
}
.login-container-wrapper-core-left
{
  width: 50%;
  display: flex;
  align-items: center;
}
.login-container-wrapper-core-left-core
{
  width: 80%;
}
.login-container-wrapper-core-left-core form 
{
  padding: 0;
  margin: 0;
}
.login-container-wrapper-core-left-core-top
{
  margin-bottom: 20px;
}
.login-container-wrapper-core-left-core-top h1 
{
  color: #219EBC;
}
.login-container-wrapper-core-left-core-top p
{
  color: #A0AEC0;
}
.login-container-wrapper-core-left-core-form
{
  padding: 20px 0px;
}
.login-container-wrapper-core-left-core-form-section
{
  margin-bottom: 20px;
}
.login-container-wrapper-core-left-core-button
{
}
.login-container-wrapper-core-left-core-button button 
{
  background-color: #219EBC;
  border: none;
  outline: none;
  color: white;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s ease;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1rem;
  margin-bottom: 10px;
}
.login-container-wrapper-core-left-core-button button:hover
{
  background-color: #023047;
  transform: scale(1.1);
  cursor: pointer;
}
.login-container-wrapper-core-left-core-button-support
{
  text-align: center;
  padding-top: 10px;
  font-size: 0.8rem;
}
.login-container-wrapper-core-left-core-button-support p 
{
  color: #A0AEC0;
  font-family: 'Poppins', sans-serif;
}
.login-container-wrapper-core-left-core-button-support a 
{
  color: #219EBC;
  text-decoration: none;
  transition: all 0.3s ease;
}
.login-container-wrapper-core-left-core-button-support a:hover 
{
  color: #023047;
}
.login-container-wrapper-core-right  
{
  width: 50%;
  background-image: url("../../../../public/landing/contact.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  height: 90%;
} 
.login-container-wrapper-core-right-layer
{
  height: 100%;
  background-color: rgba(33, 158, 188, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.login-container-wrapper-core-right-layer img 
{
  height: 80px;
  object-fit: contain;
}

/* Mobile */
@media only screen and (max-width: 800px) {
.login-container-wrapper-core
{
  width: 100%;
  padding: 20px 40px;
}
.login-container-wrapper-core-right-layer img 
{
  height: 50px;
}
}
@media only screen and (max-width: 600px)
{
.login-container-wrapper-core
{
  flex-direction: column;
  padding: 0px;
  align-items: center;
}
.login-container-wrapper-core-left
{
  width: 100%;
  justify-content: center;
}
.login-container-wrapper-core-right 
{
  width: 90%;
  margin-top: 30px;
}
}