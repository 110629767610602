
.reservations-wrapper
{
}
.reservations-wrapper-date
{
}
.reservations-wrapper-content
{
}
.reservations-container {
    height: 100%;
}
.reservations-container-el {
  display: flex;
  padding: 0px 20px;
}
.reservations-container-el-left {
  width: 50%;
}
.reservations-container-el-left h3 
{
  margin-bottom: 10px;
}
.reservations-container-el-left p {
  margin-bottom: 6px;
}
.reservations-container-el-right {
  width: 50%;
}
.reservations-container-el-right h3 
{
  margin-bottom: 10px;
}
.reservations-container-el-right p {
  margin-bottom: 6px;
}


.reservations-container-custom-cell
{
  text-align: center;
}
.reservations-container-custom-cell button 
{
  padding: 5px 10px;
  color: white;
  background-color: #023047;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.reservations-container-custom-cell button:hover 
{
  cursor: pointer;
  transform: scale(1.05);
  color: rgb(195, 195, 195);
}

.reservations-container-custom-cell a 
{
  text-decoration: none;
  padding: 5px 10px;
  color: white;
  background-color: #023047;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: block;
}
.reservations-container-custom-cell a:hover 
{
  cursor: pointer;
  transform: scale(1.05);
  color: rgb(195, 195, 195);

}