.fallback-page-container {
  height: 100vh;
  background-image: url("../../../../public/bg/fallback.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fallback-page-container-title {
  font-size: 8rem;
  margin-bottom: 30px;
  font-weight: bold;
}
.fallback-page-container-header {
  font-size: 2rem;
  margin-bottom: 40px;
  font-weight: bold;
}
.fallback-page-container-action a {
  margin-top: 100px;
  background-color: rgb(217, 187, 128);
  padding: 20px 30px;
  text-decoration: none;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.3rem;
  transition: all 0.3s ease;
  outline: none;
}
.fallback-page-container-action {
  transition: all 0.3s ease;
}
.fallback-page-container-action:hover {
  transform: scale(1.1);
}
