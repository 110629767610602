.daterange-selector-container {
  /* height: 70px; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 20px 0px;

  -webkit-box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.26);
}
.daterange-selector-container-col {
  width: 30%;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.daterange-selector-container-col p {
  margin-bottom: 5px;
}
.daterange-selector-container-col input {
  padding: 5px 10px;
  font-size: 1rem;
  width: 100%;
  animation: all 0.3s ease;
  outline: none;
}
.daterange-selector-container-col input:focus {
  border: 1px solid gray;
}
.daterange-selector-container-col-arrow {
  height: 30px;
  object-fit: contain;
}
.daterange-selector-container-action {
  width: 30%;
  display: flex;
  align-items: center;
  height: 100%;
}
.daterange-selector-container-action-range {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.daterange-selector-container-action-range img {
  height: 24px;
  object-fit: contain;
}
.daterange-selector-container-action-range p {
  margin-left: 20px;
  font-size: 1.2rem;
}
.daterange-selector-container-action-action {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.daterange-selector-container-action-action-warning
{
  font-size: 0.7rem;
  position: absolute;
  top:0;
  left: 0;
  height: 40px;
  width: 120%;
  transform: translateY(-40px);
  display: flex;
  align-items: center;
}
.daterange-selector-container-action-action-warning img 
{
  height: 15px;
  object-fit: contain;
  margin-right: 10px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--baseline-color);
  color: #fff;
}
/* TODO: extract all classes and fully custoize calendar based on cssLanding variables */

/* react-datepicker */

/* .calendarwithprice .react-datepicker__day
{
    width: 5rem !important;
    transition: all 0.3s ease;
    border: 1px solid black;
}
.calendarwithprice .react-datepicker__day:hover 
{
    background-color: rgb(207, 207, 207) !important;
    border-radius: 10px;
    border: 1px solid transparent;
}
.calendarwithprice .react-datepicker-custom-day-core
{
    line-height: 1rem;
    padding: 5px;
    transition: all 0.3s ease;
}
.calendarwithprice .react-datepicker-custom-day-core-top
{

}
.calendarwithprice .react-datepicker-custom-day-core-bot 
{
    font-size: 0.6rem !important;
}
.calendarwithprice .react-datepicker-custom-day-core img 
{
    height: 8px;
    object-fit: contain;
} */
