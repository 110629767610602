/* Modal */
.reservations-container-modal-wrapper 
{
    background-color: white;
    height: 90vh;
    width: 80vw;
    overflow-y: auto;
    outline: none;
    border-radius: 10px;
    padding: 20px;
}
.reservations-container-modal-wrapper-top
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.reservations-container-modal-wrapper-bot
{
}
.reservations-container-modal-wrapper-bot-calendar
{
    width: 35%;
}
.reservations-container-modal-wrapper-bot-calendar-core
{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reservations-container-modal-wrapper-bot-calendar-core-day
{
    border: 1px solid gray;    
}
.reservations-container-modal-wrapper-bot-calendar-core-day img 
{
    height: 13px;
    object-fit: contain;
}
.reservations-container-modal-wrapper-bot-calendar-results
{
}
.reservations-container-modal-wrapper-bot-calendar-results-dayswidget
{
}
.reservations-container-modal-wrapper-bot-calendar-results-dayswidget span 
{
    margin: 5px;
    padding: 4px 8px;
    border-radius: 8px;
    color: white;
}
.reservations-container-modal-wrapper-bot-calendar-results-dayswidget-container
{
    display: flex;
    flex-wrap: wrap;
}

.reservations-container-modal-wrapper-bot-calendar-results-info
{
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}
.reservations-container-modal-wrapper-bot-calendar-results-info img 
{
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}
.reservations-container-modal-wrapper-bot-tabs
{
    width: 65%;
}
.reservations-container-modal-wrapper-footer
{
    display: flex;
    padding-top: 10px;
}
.reservations-container-modal-wrapper-footer-left 
{
    width: 80%;
    font-size: 0.9rem;
}
.reservations-container-modal-wrapper-footer-right 
{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.reservations-container-modal-wrapper-footer-right button 
{
    outline: none;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    background-color: #219EBC;
    color: white;
    transition: all 0.3s ease;
}
.reservations-container-modal-wrapper-footer-right button:hover 
{
    transform: scale(1.1);
    cursor: pointer;
}