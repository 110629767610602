/* Array editor */
.editors-array-type-container-add {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.editors-array-type-container-items {
  display: flex;
  flex-wrap: wrap;
}
.editors-array-type-container-item {
  margin: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editors-array-type-container-item img {
  object-fit: contain;
  width: 150px;
  height: 200px;
  margin-bottom: 10px;
}
