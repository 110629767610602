.footer-container {
  min-height: 300px;
  background-color: #fafafb;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  padding: 40px;
}
.footer-container-left {
  width: 35%;
}
.footer-container-left-header {
  display: flex;
  align-items: center;
}
.footer-container-left-header img {
  height: 60px;
  object-fit: contain;
}
.footer-container-left-header p {
  margin-left: 30px;
  font-size: 1.5rem;
  font-weight: bold;
}
.footer-container-left-desc {
  padding: 10px;
  margin-top: 20px;
}
.footer-container-left-social {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
}
.footer-container-left-social a {
  margin-right: 40px;
  transition: all 0.3s ease;
}
.footer-container-left-social a:hover {
  transform: scale(1.1);
}
.footer-container-left-social a img {
  height: 25px;
  object-fit: contain;
}
.footer-container-middle {
  width: 20%;
}
.footer-container-middle-header {
  font-weight: bold;
  font-size: 1.4rem;
}
.footer-container-middle-sections {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.footer-container-middle-sections a {
  margin-bottom: 10px;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  text-decoration: none;
  color: black;
}
.footer-container-middle-sections a:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.footer-container-right {
  width: 30%;
}
.footer-container-middle-legal {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.footer-container-middle-legal a {
  transition: all 0.3s ease;
}
.footer-container-middle-legal a:hover {
  transform: scale(1.05);
}
.footer-container-middle-legal img {
  width: 80%;
  object-fit: contain;
  margin-right: 20px;
}
