.history-container
{
    padding: 20px;
    height: 85vh;
    background-color: white;
    margin: 0px 20px;
    border-radius: 20px;
    overflow-y: scroll;
}
.history-container-core-select
{
    margin-bottom: 15px;
}
.history-container-core-select button 
{
    border-radius: 10px;
    padding: 10px 20px;
    transition: all 0.3s ease;
    color: #219EBC;
    border: 2px solid #219EBC;
    font-weight: bold;
    background-color: white;
    margin-right: 20px;
}
.history-container-core-select button:hover 
{
    transform: scale(1.05);
    background-color: #219EBC;
    color: white;
    cursor: pointer;
}
.history-container-core-content
{
}
.history-container-core-content-item
{
    margin-bottom: 15px;
}
.history-container-core-content-item-title
{
    display: flex;
}
.history-container-core-content-item-title-left 
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.history-container-core-content-item-title-left img 
{
    height: 20px;
    object-fit: contain;
}
.history-container-core-content-item-title-right 
{
}
.history-container-core-content-item-title h4 
{
    margin: 0px;
    color: #2D3748;
}
.history-container-core-content-item-title h5 
{
    color: #A0AEC0;
}
.history-container-core-content-item-content
{
}
.history-container-core-content-item-desc
{
    display: flex;
    margin-bottom: 10px;
}
.history-container-core-content-item-desc img 
{
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}
.history-container-core-content-item-sub 
{
}
.history-container-core-content-item-sub-core
{
    margin-bottom: 5px;
}
.history-container-core-content-item-sub-core-top
{
    display: flex;
    align-items: center;
}
.history-container-core-content-item-sub-core-top img 
{
    height: 7px;
    object-fit: contain;
    margin-right: 10px;
}
.history-container-core-content-item-sub-core-top p
{
    font-size: 0.9rem;
}
.history-container-core-content-item-sub-core-bot 
{
    color: #A0AEC0;
}
.history-container-core-content-item-sub-core-bot p 
{
    font-size: 0.7rem !important;
}