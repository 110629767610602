/* Checkout modal */
.checkout-container-modal {
  height: 70vh;
  width: 70vw;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
}

.checkout-container-modal-exit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5%;
}
.checkout-container-modal-exit img {
  height: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
}
.checkout-container-modal-exit img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.checkout-container-modal-content {
  height: 95%;
  padding-top: 15px;
}
.checkout-container-modal-content-pages {
  height: 80%;
}
.checkout-container-modal-content-actions {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Page 1 */
.checkout-container-modal-inputs {
  display: flex;
}
.checkout-container-modal-inputs-col {
  height: 100%;
  width: 50%;
}
.modal-modal-next-page-el {
  height: 80px;
  display: flex;
  align-items: center;
  padding-right: 30px;
}
/* Page 2 */
.checkout-container-modal-check {
  padding: 20px;
}
.checkout-container-modal-check-col {
  width: 49%;
  height: 100%;
}
.checkout-container-modal-check-separator {
  width: 2px;
  background-color: rgb(170, 170, 170);
  margin: 30px 20px 0px 20px;
}
.checkout-container-modal-check-col-header {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.4rem;
}
.checkout-container-modal-check-col-content {
}
.checkout-container-modal-check-col-content-row {
  display: flex;
  color: gray;
  margin-bottom: 5px;
  font-size: 1rem;
}
.checkout-container-modal-check-col-content-row-left {
  width: 50%;
}
.checkout-container-modal-check-col-content-row-right {
  width: 50%;
  text-align: right;
  font-style: italic;
}
.checkout-container-modal-check-col-content-row-right-even {
  width: 33.33%;
}
.checkout-container-modal-check-top {
  display: flex;
}
.checkout-container-modal-check-bottom {
  margin-top: 30px;
  display: flex;
  /* justify-content: flex-end; */
  align-items: flex-end;
  font-size: 1.6rem;
  font-weight: bold;
  flex-direction: column;
}
.checkout-container-modal-check-bottom-separator {
  height: 2px;
  background-color: rgb(175, 175, 175);
  width: 200px;
  margin-bottom: 10px;
}
/* Page 3 */
.checkout-container-modal-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 10%;
}
.checkout-container-modal-payment-separator {
  width: 200px;
  height: 2px;
  background-color: rgb(190, 190, 190);
  margin-bottom: 10px;
}
.checkout-container-modal-payment button {
  padding: 10px 15px;
  border: none;
  outline: none;
  font-size: 1.1rem;
  background-color: rgb(217, 187, 128);
  transition: all 0.3s ease;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-top: 50px;
}

.checkout-container-modal-payment button:hover {
  transform: scale(1.1);
  cursor: pointer;
}
