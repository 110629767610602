.status-page-cont {
  min-height: 100vh;
}
.status-page-cont-core {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../public/bg/landing.png");
  background-size: cover;
  padding: 50px;
}
.status-page-cont-core-body {
  width: 30vw;
  height: 100%;
  padding: 25px;
  background-color: white;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 49px -36px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 49px -36px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 49px -36px rgba(0, 0, 0, 0.75);
}
.status-page-cont-core-body-back {
  padding: 4px 0px;
}
.status-page-cont-core-body-back img {
  transition: all 0.3s ease;
  transform: scale(1);
}
.status-page-cont-core-body-back img:hover {
  transform: scale(1.3);
  cursor: pointer;
}
.status-page-cont-core-body-header {
  padding: 5px 10px;
}
.status-page-cont-core-body-header p {
  font-weight: bold;
  color: #e84855;
  margin-bottom: 3px;
}
.status-page-cont-core-body-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 20px;
}
.status-page-cont-core-body-icon p {
  margin-bottom: 20px;
  color: #e84855;
  font-size: 1.2rem;
  font-weight: bold;
}
.status-page-cont-core-body-top-fields {
  margin-bottom: 20px;
}
.status-page-cont-core-body-top-fields-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.status-page-cont-core-body-top-rooms {
}
.status-page-cont-core-body-top-rooms-top {
  font-weight: bold;
  margin-bottom: 10px;
}
.status-page-cont-core-body-top-rooms-items-item {
  display: flex;
  border-bottom: 1px dashed gray;
  padding: 10px 0px;
}
.status-page-cont-core-body-top-rooms-item-left {
  width: 50%;
}
.status-page-cont-core-body-top-rooms-item-center {
  width: 25%;
  display: flex;
  justify-content: center;
}
.status-page-cont-core-body-top-rooms-item-right {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
}
.status-page-cont-core-body-top-total {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  padding: 20px 0px;
}
.status-page-cont-core-body-top-total-left {
  width: 25%;
  display: flex;
  justify-content: center;
}
.status-page-cont-core-body-top-total-right {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}
.status-page-cont-core-body-top-receipt {
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
}
.status-page-cont-core-body-top-receipt a {
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: #e84855;
  color: white;
  border: none;
  outline: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  transform: scale(1);
  text-decoration: none;
  text-align: center;
}
.status-page-cont-core-body-top-receipt a:hover {
  cursor: pointer;
  color: rgb(209, 209, 209);
  transform: scale(1.05);
}
.status-page-cont-core-body-top-support {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-page-cont-core-body-top-support a {
  text-decoration: none;
  color: #e84855;
}
