.add-property-container {
  height: 100%;
  width: 100%;
}
.sudo-btn-action {
  padding: 15px 25px;
  background-color: #023047;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  transition: all 0.3s ease;
  max-width: 200px;
}
.sudo-btn-action:hover {
  transform: scale(1.05);
  cursor: pointer;
}
