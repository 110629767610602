.account-container
{
    height: 100%;
}
.account-container-top
{
}
.account-container-top-bg
{
    background-color: #219EBC;
    height: 120px;
    border-radius: 20px;
    background-image: url('../../../../public/adminDash/profileBg.png');
    background-size: cover;
}
.account-container-top-profile
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    transform: translateY(-80px);
}
.account-container-top-profile-core
{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 20px;
    display: flex;
}
.account-container-top-profile-core-img
{
    background-color: #219EBC;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 80px;
    height: 80px;
}
.account-container-top-profile-core-img img
{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.account-container-top-profile-core-text
{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.account-container-top-profile-core-text h4 
{
    color: #2D3748;
}
.account-container-top-profile-core-text p 
{
    font-size: 0.8rem;
    margin-top: 5px;
    color: #718096;
}
.account-container-bot
{
    padding: 20px;
    transform: translateY(-80px);
    display: flex;
    justify-content: center;
}
.account-container-bot-core
{
    padding: 20px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
    background-color: white;
    margin-right: 20px;
}
.account-container-bot-core-header
{
    margin-bottom: 10px;
}
.account-container-bot-core-header p 
{
    font-size: 1rem;
    font-weight: bold;
    color: #2D3748;
}
.account-container-bot-core-rows
{
}
.account-container-bot-core-rows-el
{
    display: flex;
    margin-bottom: 5px;
}
.account-container-bot-core-rows-el h4 
{
    margin-right: 10px;
    color: #718096;
    width: 120px;
    font-size: 0.8rem;
}
.account-container-bot-core-rows-el p 
{   
    color: #A0AEC0;
    font-size: 0.7rem;
}