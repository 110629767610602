.checkout-container {
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.26);
  min-height: 300px;
  padding: 20px;
}
.checkout-container-header {
  font-weight: bold;
  font-size: 1.6rem;
}
.checkout-container-empty {
  height: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.checkout-container-empty img {
  height: 50px;
  object-fit: contain;
  margin-bottom: 20px;
}
.checkout-container-items {
  margin-top: 20px;
}
.checkout-container-end {
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
}
.checkout-container-end-left {
  width: 50%;
}
.checkout-container-end-left-icon {
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.checkout-container-end-left-icon img {
  height: 50px;
  object-fit: contain;
}
.checkout-container-end-left-center {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 35px;
}
.checkout-container-end-left-center img {
  transform: rotate(90deg);
  height: 30px;
  object-fit: contain;
}
.checkout-container-end-right {
  width: 50%;
}
.checkout-container-end-right-top {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0px 15px;
}
.checkout-container-end-right-top p {
  font-size: 1.3rem;
}
.checkout-container-end-right-top span {
  font-weight: bold;
  font-size: 1.5rem;
}
.checkout-container-end-right-bottom {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
