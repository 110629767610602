.rooms-container {
}
.rooms-container-total {
  padding: 5px 0px;
  font-weight: bold;
  font-size: 1rem;
}
.rooms-container-holder {
}
.rooms-container-item {
  background-color: white;
  -webkit-box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.26);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
}
.rooms-container-item-top {
  margin-bottom: 10px;
  display: flex;
}
.rooms-container-item-top-unavailable {
  padding-top: 20px;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.rooms-container-item-top-unavailable img {
  margin-left: 20px;
  height: 25px;
  object-fit: contain;
}
.rooms-container-item-top-left {
  width: 30%;
  height: 500px;
  overflow-y: scroll;
}
.rooms-container-item-top-right {
  width: 70%;
  padding: 0px 30px;
}
.rooms-container-item-top-right-top {
}
.rooms-container-item-top-right-top h2 {
  margin-bottom: 10px;
}

.rooms-container-item-top-right-center {
  /* height: 33%; */
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.rooms-container-item-top-right-centerfacility {
  width: 33.33%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.rooms-container-item-top-right-centerfacility img {
  margin-right: 20px;
}
.rooms-container-item-top-right-bottom {
  margin-top: 30px;
  /* height: 120px; */
  display: flex;
}
.rooms-container-item-top-right-bottom-left {
  width: 33%;
}
.rooms-container-item-top-right-bottom-left-top {
}
.rooms-container-item-top-right-bottom-left-bot {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.rooms-container-item-top-right-bottom-left-bot img {
  height: 30px;
  object-fit: contain;
}
.rooms-container-item-top-right-bottom-left-bot p {
  margin-left: 10px;
  font-size: 1.2rem;
}
.rooms-container-item-top-right-bottom-prices {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rooms-container-item-top-right-packages {
  margin-top: 50px;
}
.rooms-container-item-top-right-packages-pack {
  padding: 15px 20px;
  /* height: 100px; */
  background-color: var(--secondary-color);
  margin-top: 10px;
  border-radius: 15px;
  display: flex;
}
.rooms-container-item-top-right-packages-pack-left {
  width: 50%;
}
.rooms-container-item-top-right-packages-pack-left-top {
  display: flex;
  align-items: center;
}
.rooms-container-item-top-right-packages-pack-left-top p {
  font-size: 1.2rem;
}
.rooms-container-item-top-right-packages-pack-left-top span {
  font-weight: bold;
  font-size: 1rem;
  margin-left: 5px;
}
.rooms-container-item-top-right-packages-pack-left-bot {
  font-weight: bold;
  margin-top: 5px;
}

.rooms-container-item-top-right-packages-pack-center {
  width: 30%;
  text-align: end;
}
.rooms-container-item-top-right-packages-pack-center-top {
}
.rooms-container-item-top-right-packages-pack-center-bot {
  font-weight: bold;
  margin-top: 5px;
  font-size: 1.4rem;
}
.rooms-container-item-top-right-packages-pack-right {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rooms-container-item-slider {
  height: 100%;
}
.rooms-container-item-slider img {
  height: 100%;
  object-fit: cover;
  position: center;
  border-radius: 5px;
}

.rooms-container-item-top-right-bottom-prices-modal {
  height: 60vh;
  width: 70vw;
  background-color: white;
}
