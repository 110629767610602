.leftbar-container {
  height: 100%;
  width: 13%;
  background-color: #f2f2f2;
}
.leftbar-container-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
}
.fade-line {
  margin-top: 10px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(255, 0, 0, 0), #c0c0c0, rgba(255, 0, 0, 0));
}
.leftbar-container-logo img {
  height: 45px;
  object-fit: contain;
  transition: all 0.3s ease;
}
.leftbar-container-logo img:hover 
{
  transform: scale(1.05);
}
.leftbar-container-items {
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.leftbar-container-items-el {
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  height: 60px;
}
.leftbar-container-items-el:hover 
{
  background-color: white;
}
.leftbar-container-items-el:hover span 
{
  color: black;
}
.leftbar-container-items-el img 
{
  height: 25px;
  object-fit: contain;
}
.leftbar-container-items-el-selected {
  background-color: white;
  -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
  box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
  background-color: white;
  border-radius: 15px;
}
.leftbar-container-items-el-img-selected
{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #219EBC;
  padding: 8px;
  border-radius: 15px;
}
.leftbar-container-items-el-img
{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 8px;
  border-radius: 15px;
}
.leftbar-container-items-el-content
{
  width: 80%;
  font-size: 0.9rem;
  color: #A0AEC0;
  padding-left: 15px;
  font-weight: bold;
}
.leftbar-container-items-el-selected span 
{
  color: #2D3748;
  font-weight: bold;
} 
.leftbar-container-help
{
  width: 100%;
  background-color: #219EBC;
  border-radius: 15px;
  padding: 15px;
  margin-left: 10px;
}
.leftbar-container-help-top
{
  margin-bottom: 10px;
}
.leftbar-container-help-top-imbox
{
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 8px;
  width: 40px;
}
.leftbar-container-help-top-imbox img 
{
  height: 20px;
  object-fit: contain;
}
.leftbar-container-help-text
{
  margin-bottom: 10px;
  color: white;
} 
.leftbar-container-help-text h3 
{
  font-size: 0.8rem;
}
.leftbar-container-help-text p 
{
  font-size: 0.7rem;
}
.leftbar-container-help-btn
{
  display: flex;
  align-items: center;
}
.leftbar-container-help-btn a
{
  text-align: center;
  color: #2D3748;
  text-decoration: none;
  border-radius: 15px;
  background-color: white;
  font-size: 0.8rem;
  width: 100%;
  transition: all 0.3s ease;
}
.leftbar-container-help-btn a:hover 
{
  transform: scale(1.05);
}