.reservations-container-modal-tabitem
{
}
.reservations-container-modal-tabitem button 
{
    outline: none;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #219EBC;
    color: white;
    margin-left: 10px;
    transition: all 0.3s ease;
}
.reservations-container-modal-tabitem button:hover 
{
    cursor: pointer;
    transform: scale(1.1);
}
.reservations-container-modal-tabitem-facilities
{
    margin-bottom: 10px;
}
.reservations-container-modal-tabitem-facilities-checkbox
{
}
.reservations-container-modal-tabitem-facilities-checkbox-item
{
}
.reservations-container-modal-tabitem-facilities-interval
{
}
.reservations-container-modal-tabitem-facilities-interval-item
{
    margin: 10px 0px;
}
.reservations-container-modal-tabitem-facilities-interval-item p
{
    display: flex;
    align-items: center;
}
.reservations-container-modal-tabitem-facilities-interval-item span 
{
    margin: 0px 5px;
    border: 2px solid gray;
    transition: all 0.3s ease;
    display: block;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}
.reservations-container-modal-tabitem-facilities-interval-item span:hover 
{
    transform: scale(1.2);
    cursor: pointer;
}

.reservations-container-modal-tabitem-comm
{
}
.reservations-container-modal-tabitem-comm textarea
{
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    resize: none;
}
.reservations-container-modal-wrapper-footer-save-switch
{
    display: flex;
    align-items: center;
}

.reservations-container-modal-wrapper-footer-save-btn
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.reservations-container-modal-wrapper-footer-save-btn button 
{
    padding: 0px 20px;
    font-size: 1.3rem;
}