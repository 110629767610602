.topbar-container
{
}
.topbar-container-admin
{
    padding: 10px;
    display: flex;
    background-color: red; /*DON'T DELETE THIS LINE*/
}
.topbar-container-admin-left
{
    width: 80%;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}
.topbar-container-admin-left img 
{
    height: 30px;
    object-fit: contain;
    margin: 0px 20px;
}
.topbar-container-admin-right
{
    display: flex;
    width: 20%;
    justify-content: flex-end;
    padding: 0px 10px;
}
.topbar-container-admin-right a 
{
    text-decoration: none;
    margin-left: 15px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;  
    background-color: var(--baseline-color);
    color: white;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.topbar-container-admin-right a:hover 
{
    transform: scale(1.1);
}
.topbar-container-admin-right button 
{
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;  
    background-color: var(--baseline-color);
    color: white;
    font-size: 0.9rem;
}
.topbar-container-admin-right button:hover 
{
    transform: scale(1.1);
}
.topbar-container
{
    height: 9vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    color: white;
}
.topbar-container-left
{
    height: 100%;
    display: flex;
    align-items: center;
}
.topbar-container-left a
{
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
}
.topbar-container-left a img 
{
    height: 40px;
    object-fit: contain;
}
.topbar-container-left-core
{
    margin-left: 18px;
}
.topbar-container-left-core p:first-child
{
    font-size: 0.8rem;
}

.topbar-container-right 
{
    height: 100%;
}
.topbar-container-right-top
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50%;
}
.topbar-container-right-top a 
{
    text-decoration: none;
    background-color: white;
    padding: 3px 10px;
    border-radius: 5px;
    color: var(--baseline-color);
    transition: all 0.3s ease;
}
.topbar-container-right-top a:hover 
{
    transform: scale(1.1);
    background-color: rgb(208, 208, 208);
    color: white;
}
.topbar-container-right-bot 
{
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 0.8rem;
}
.topbar-container-right-bot a 
{
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
}
.topbar-container-right-bot a:hover 
{
    transform: scale(1.05);
}
.topbar-container-right-bot a p 
{
    display: inline;
    font-weight: bold;
    transition: all 0.3s ease;
    font-size: 0.8rem;
}