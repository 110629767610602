.receipt-indexes-container
{
    padding: 20px;
}
.receipt-indexes-container-top
{
    background-color: white;
    -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
    padding: 20px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 415px;
}
.receipt-indexes-container-top button 
{
    margin-left: 20px;
    border: none;
    outline: none;
    transition: all 0.3s ease;
    color: white;
    background-color: #219EBC;
    padding: 10px 20px;
    border-radius: 15px;
    font-weight: bold;
}
.receipt-indexes-container-top button:hover 
{
    transform: scale(1.05);
    cursor: pointer;
}
.receipt-indexes-container-bot 
{
    display: flex;
    flex-wrap: wrap;
}
.receipt-index-el
{
    background-color: white;
    -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
    padding: 20px;
    border-radius: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.receipt-index-el-inactive
{
    display: flex;
    flex-direction: column;
    align-items: center ;
}
.receipt-index-el-inactive button 
{
    margin-top: 10px;
    outline: none;
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    color: white;
    background-color: #219EBC;
    border-radius: 10px;
    transition: all 0.3s ease;
}
.receipt-index-el-inactive button:hover 
{
    transform: scale(1.05);
    cursor: pointer;
}