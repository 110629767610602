.dashboard-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #f2f2f2;

}
.dashboard-container-core {
  width: 87%;
  height: 100%;
}
.dashboard-container-core-content {
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 15px;
}