.landing-container {
  background-image: url("../../../../public/bg/bg_1_temp.png");
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}
.landing-container-cols {
  min-height: 100vh;
  display: flex;
  padding: 20px;
  position: relative;
}
.landing-container-left-col {
  width: 68%;
  margin-right: 20px;
}
.landing-container-left-right-col {
  width: 32%;
}
.landing-circular-loading
{
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}