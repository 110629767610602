.admin-container
{
    height: 100%;
    width: 100%;
    padding: 10px;
}
.admin-container-my-page
{
    background-color: white;
    -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16); 
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.16);
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    margin-bottom: 20px;
    width: 24%;
}
.admin-container-my-page-core
{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #219ebc;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    text-decoration: none;
    transition: all 0.3s ease;
    width: 100%;
}
.admin-container-my-page-core:hover 
{
    cursor: pointer;
    transform: scale(1.05);
}
.admin-container-my-page-core img
{
    height: 30px;
    object-fit: contain;
}
.admin-container-my-page-core p 
{
    margin-left: 30px;
}