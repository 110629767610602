.checkout-container-items-el {
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  border: 2px solid rgb(225, 223, 223);
}
.checkout-container-items-el-title {
  display: flex;
}
.checkout-container-items-el-title-left {
  width: 70%;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.3rem;
}
.checkout-container-items-el-title-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.checkout-container-items-el-title-right span {
  font-size: 0.8rem;
}
.checkout-container-items-el-collapse {
  margin-top: 10px;
  
}
.checkout-container-items-el-collapse-title {
}
.checkout-container-items-el-collapse-items {
  margin-bottom: 20px;
}
.checkout-container-items-el-collapse-items-row {
  margin-bottom: 10px;
  display: flex;
}
.checkout-container-items-el-collapse-items-row-left {
  width: 80%;
  display: flex;
  align-items: center;
  padding-left: 8px;
}
.checkout-container-items-el-collapse-items-row-left p {
}
.checkout-container-items-el-collapse-items-row-left span {
  font-weight: bold;
  margin-left: 5px;
}

/* Magic row type (increment type) */
.checkout-container-items-el-collapse-items-row-left-magic {
  width: 80%;
  display: flex;
}
.checkout-container-items-el-collapse-items-row-left-magic-input {
  display: flex;
  align-items: center;
}
.checkout-container-items-el-collapse-items-row-left-magic-input img {
  height: 10px;
  object-fit: contain;
  transition: all 0.3s ease;
}
.checkout-container-items-el-collapse-items-row-left-magic-input img:hover {
  cursor: pointer;
  transform: scale(1.5);
}
.checkout-container-items-el-collapse-items-row-left-magic-input p {
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0px 10px;
  padding: 2px 10px;
  color: gray;
  width: 36px;
  text-align: center;
}
.checkout-container-items-el-collapse-items-row-left-magic-data {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.checkout-container-items-el-collapse-items-row-left-magic-data p {
}
.checkout-container-items-el-collapse-items-row-left-magic-data span {
  font-weight: bold;
  margin-left: 5px;
}

.checkout-container-items-el-collapse-items-row-right {
  width: 20%;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-container-items-el-collapse-items-row-right p {
  transition: all 0.3s ease;
  font-size: 1rem;
}
.checkout-container-items-el-collapse-items-row-right p:hover {
  cursor: pointer;
  font-size: 1.1rem;
}
.checkout-container-items-el-collapse-items-extra-users {
}
.checkout-container-items-el-collapse-items-extra-users-header {
  margin-top: 20px;
  margin-bottom: 10px;
}

.checkout-container-items-el-price {
  display: flex;
  align-items: center;
}
.checkout-container-items-el-price-left {
  width: 50%;
  font-size: 1.5rem;
  font-weight: bold;
}
.checkout-container-items-el-price-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

/* Modal */
.checkout-container-modal-details {
  height: 200px;
  background-color: white;
  width: 70vw;
  outline: none;
  padding: 15px;
  border-radius: 15px;
}
.checkout-container-modal-details-exit {
  height: 15%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.checkout-container-modal-details-exit img {
  height: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
}
.checkout-container-modal-details-exit img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.checkout-container-modal-details-content {
  height: 85%;
  display: flex;
}
.checkout-container-modal-details-content-left {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout-container-modal-details-content-left img {
  height: 70%;
}
.checkout-container-modal-details-content-right {
  width: 80%;
  margin-left: 10px;
}
.checkout-container-modal-details-content-right-head {
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.checkout-container-modal-details-content-right-content {
}
