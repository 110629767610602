.calendarwithprice
{
}
.calendarwithprice-core
{
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 70vw;
    background-color: white;
    border-radius: 10px;
}
.calendarwithprice-core-save
{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.calendarwithprice-core-save-warning
{
    height: 35px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.calendarwithprice-core-save-warning img 
{
    height: 25px;
    object-fit: contain;
    margin-right: 20px;
}
/* .calendarwithprice-core-save button 
{
    padding: 10px 20px;
    outline: none;
    border: none;
    color: white;
    transition: all 0.3s ease;  
    border-radius: 10px;
    font-size: 1.1rem;
}
.calendarwithprice-core-save button:hover 
{
    transform: scale(1.1);
    cursor: pointer;
} */
.calendarwithprice-core-save-enabled
{
    background-color: black;
    padding: 10px 20px;
    outline: none;
    border: none;
    color: white;
    transition: all 0.3s ease;  
    border-radius: 10px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
}
.calendarwithprice-core-save-enabled:hover 
{
    transform: scale(1.1);
    cursor: pointer;
}
.calendarwithprice-core-save-disabled 
{
    background-color: #d6d6d6;
    padding: 10px 20px;
    outline: none;
    border: none;
    color: white;
    transition: all 0.3s ease;  
    border-radius: 10px;
    font-size: 1.1rem;
}




/* Calendar classes override */

.calendarwithprice-core .react-datepicker__day
{
    width: 3.5rem !important;
    transition: all 0.3s ease;
    border: 1px solid transparent;
}
.calendarwithprice-core .react-datepicker__day:hover 
{
    background-color: rgb(207, 207, 207) !important;
    border-radius: 10px;
    border: 1px solid transparent;
}
.react-datepicker__day--selected 
{
    background-color: black !important;
}
.react-datepicker__day--in-range
{
    background-color: rgb(123, 123, 123) !important;
}
.calendarwithprice-core .react-datepicker-custom-day-core
{
    line-height: 1rem;
    padding: 5px;
    transition: all 0.3s ease;
    border: 1px solid gray;
    border-radius: 5px;

}
.calendarwithprice-core .react-datepicker-custom-day-core-top
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.calendarwithprice-core .react-datepicker-custom-day-core-bot 
{
    font-size: 0.6rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.calendarwithprice-core .react-datepicker-custom-day-core img 
{
    height: 15px;
    margin-left: 5px;
    object-fit: contain;
} 