.hoteldetails-title-container {
}
.hoteldetails-title-container p {
  font-size: 1.7rem;
  font-weight: bold;
}
.hoteldetails-title-container-border {
  height: 5px;
  background-color: var(--baseline-color);
  width: 100px;
  margin-top: 5px;
}
