.top-dash-container
{
    height: 10vh;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.top-dash-container-left
{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.top-dash-container-right 
{
    display: flex;
}
.top-dash-container-right-search
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}
.top-dash-container-right-btns
{
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top-dash-container-right-btns-section
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}
.top-dash-container-right-btns-section img 
{
    height: 20px;
    object-fit: contain;
}
.top-dash-container-right-btns-section-text
{
    padding-left: 10px;
}
.top-dash-container-right-btns-section-text p 
{
    font-weight: bold;
}
.top-dash-container-right-btns-section button 
{
    padding: 10px 20px;
    outline: none;
    border: none;
    background-color: #219EBC;
    color: white;
    border-radius: 7px;
    transition: all 0.3s ease;
}
.top-dash-container-right-btns-section button:hover 
{
    transform: scale(1.1);
    cursor: pointer;
}